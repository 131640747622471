<template>
  <section :class="[$style.wrapper, $style[$mq]]">
    <h3>{{ $t("We Help") }}</h3>
    <div>
      <span
        ><icon-base v-bind="size" icon-name="virtualcurrencyexchanges">
          <icon-virtualcurrencyexchanges /> </icon-base
      ></span>
      <h4>{{ $t("Virtual Currency Exchanges") }}</h4>
      <p>{{ $t('MISC["Virtual Currency Exchanges"]') }}</p>
    </div>

    <div>
      <span
        ><icon-base v-bind="size" icon-name="otctradingdesk">
          <icon-otctradingdesk /> </icon-base
      ></span>
      <h4>{{ $t("OTC Trading Desks") }}</h4>
      <p>{{ $t('MISC["OTC Trading Desk"][0]') }}</p>
      <p>{{ $t('MISC["OTC Trading Desk"][1]') }}</p>
    </div>

    <div>
      <span
        ><icon-base
          v-bind="size"
          icon-name="stablecoins"
          view-box="0, 0, 219, 252"
        >
          <icon-stablecoins /> </icon-base
      ></span>
      <h4>{{ $t("Stablecoins") }}</h4>
      <p>{{ $t("MISC.Stablecoins") }}</p>
    </div>

    <div>
      <span
        ><icon-base v-bind="size" icon-name="icos" view-box="0, 0, 222, 272">
          <icon-icos /> </icon-base
      ></span>
      <h4>{{ $t("ICOs") }}</h4>
      <p>{{ $t("MISC.ICOs[0]") }}</p>
      <p>{{ $t("MISC.ICOs[1]") }}</p>
    </div>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase";
import IconVirtualcurrencyexchanges from "@/components/icons/IconVirtualcurrencyexchanges";
import IconOtctradingdesk from "@/components/icons/IconOtctradingdesk";
import IconStablecoins from "@/components/icons/IconStablecoins";
import IconIcos from "@/components/icons/IconIcos";

export default {
  name: "KoiHomeAlt",
  data() {
    return {};
  },
  props: {},
  computed: {
    size() {
      return {
        xs: { width: "58", height: "58" },
        sm: { width: "68", height: "68" },
        md: { width: "72", height: "72" },
        lg: { width: "96", height: "96" },
        xl: { width: "120", height: "120" }
      }[this.$mq];
    }
  },
  components: {
    IconBase,
    IconVirtualcurrencyexchanges,
    IconOtctradingdesk,
    IconStablecoins,
    IconIcos
  }
};
</script>

<style module>
.wrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding: 12vw 8vw 0;
  position: relative;
  text-align: left;
  & > h3 {
    font-size: 2rem;
    left: 8vw;
    margin: 0;
    position: absolute;
    top: 4vw;
  }
  & div {
    box-sizing: border-box;
    margin-bottom: 6vw;
    width: 15rem;
  }
  & h4 {
    font-size: 1.6rem;
    line-height: 1.2;
    margin: 1.6rem 0 1.2rem;
    min-height: 3.84rem;
  }
  & p {
    font-size: 0.9rem;
    line-height: 1.8;
    margin: 0 0 1rem;
  }
  & span {
    background: transparent;
    display: inline-block;
    font-size: 0;
    padding: 3.75rem;
  }
  &.xs,
  &.sm {
    padding: 16vw 8vw 0;
    & > h3 {
      top: 4.8vw;
    }
    & div {
      margin-bottom: 12vw;
      width: 9.8rem;
    }
    & span {
      padding: 2.4rem;
    }
  }
}
</style>
