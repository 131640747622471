<template>
  <section :class="[$style.wrapper, $style[$mq]]">
    <h3>{{ $t("Koi Compliance Advantages") }}</h3>
    <div>
      <span
        ><icon-base v-bind="size" icon-name="crypto" view-box="0, 0, 184, 102">
          <icon-crypto /> </icon-base
      ></span>
      <h4>{{ $t("Cryptocurrency KYC/AML Expertise") }}</h4>
      <p>{{ $t("MISC.Cryptocurrency") }}</p>
    </div>

    <div>
      <span
        ><icon-base
          v-bind="size"
          icon-name="coverage"
          view-box="0, 0, 147, 138"
        >
          <icon-coverage /> </icon-base
      ></span>
      <h4>{{ $t("Complete Coverage") }}</h4>
      <p>{{ $t('MISC["Complete Coverage"]') }}</p>
    </div>

    <div>
      <span
        ><icon-base
          v-bind="size"
          icon-name="efficient-alt"
          view-box="0, 0, 127, 127"
        >
          <icon-efficient-alt /> </icon-base
      ></span>
      <h4>{{ $t("Efficient") }}</h4>
      <p>{{ $t('MISC["Efficient Alt"]') }}</p>
    </div>

    <div>
      <span
        ><icon-base v-bind="size" icon-name="success" view-box="0, 0, 129, 129">
          <icon-success /> </icon-base
      ></span>
      <h4>{{ $t("Proven Success") }}</h4>
      <p>{{ $t('MISC["Proven Success"]') }}</p>
    </div>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase";
import IconCrypto from "@/components/icons/IconCrypto";
import IconCoverage from "@/components/icons/IconCoverage";
import IconEfficientAlt from "@/components/icons/IconEfficientAlt";
import IconSuccess from "@/components/icons/IconSuccess";

export default {
  name: "KoiAdvantage",
  data() {
    return {};
  },
  props: {},
  computed: {
    size() {
      return {
        xs: { width: "29", height: "29" },
        sm: { width: "34", height: "34" },
        md: { width: "36", height: "36" },
        lg: { width: "48", height: "48" },
        xl: { width: "60", height: "60" }
      }[this.$mq];
    }
  },
  components: {
    IconBase,
    IconCrypto,
    IconCoverage,
    IconEfficientAlt,
    IconSuccess
  }
};
</script>

<style module>
.wrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding: 12vw 8vw 4vw;
  position: relative;
  text-align: left;
  & > h3 {
    font-size: 2rem;
    left: 8vw;
    margin: 0;
    position: absolute;
    top: 4vw;
  }
  & div {
    border-bottom: 1px solid #979797;
    box-sizing: border-box;
    margin-bottom: 4vw;
    padding: 0 2vw 2vw 0;
    width: 50%;
    &:nth-child(4),
    &:nth-child(5) {
      border-bottom-color: transparent;
    }
  }
  & h4 {
    font-size: 1.6rem;
    line-height: 1.2;
    margin: 1.6rem 0 1.2rem;
  }
  & p {
    font-size: 0.9rem;
    line-height: 1.8;
    margin: 0 0 1rem;
  }
  & span {
    background: transparent;
    display: inline-block;
    font-size: 0;
    padding: 0;
  }
  &.xs,
  &.sm {
    padding: 32vw 8vw 0;
    & > h3 {
      top: 2.5vw;
    }
    & div {
      margin-bottom: 12vw;
      width: 100%;
      &:nth-child(4) {
        border-bottom-color: #979797;
      }
    }
    & span {
      padding: 0;
    }
  }
}
</style>
