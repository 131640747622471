<template>
  <main :class="{ [$style.main]: true, [$style[$mq]]: true }">
    <aside>
      <h3>{{ $t("Our Services") }}</h3>
      <ul>
        <li
          v-for="({ title }, index) in services"
          :key="index"
          :class="{ [$style.active]: active === index }"
          @click="active = index"
        >
          <span>{{ title }}</span>
          <hr v-if="active === index" />
        </li>
      </ul>
    </aside>
    <div :class="$style.wrapper">
      <icon-base
        :class="$style.icon"
        v-bind="size"
        :icon-name="activeContent.icon.name"
        :view-box="activeContent.icon.size"
      >
        <component :is="activeContent.icon.com"></component>
      </icon-base>
      <h3>{{ activeContent.title }}</h3>
      <p v-for="(item, index) in activeContent.content" :key="index">
        {{ item }}
      </p>
      <router-link
        v-if="activeContent.link"
        :class="[$style.contact]"
        to="/contact"
        v-ripple
      >
        {{ activeContent.link }}
        <icon-base width="12" height="12" iconName="arrowright">
          <icon-arrowright />
        </icon-base>
      </router-link>
    </div>
  </main>
</template>

<script>
import IconBase from "@/components/IconBase";
import IconArrowright from "@/components/icons/IconArrowright";
import IconIntegration from "@/components/icons/IconIntegration";
import IconPolicies from "@/components/icons/IconPolicies";
import IconStuff from "@/components/icons/IconStuff";
import IconAndMore from "@/components/icons/IconAndMore";

export default {
  name: "KoiService",
  data() {
    return {
      active: 0,
      services: [
        {
          title: this.$t("Policies & Procedures"),
          content: this.$t("SERVICE[0]"),
          icon: {
            name: "policies",
            size: "0 0 352 334",
            com: IconPolicies
          }
        },
        {
          title: this.$t("Integration & Configuration"),
          content: this.$t("SERVICE[1]"),
          icon: {
            name: "integration",
            size: "0 0 307 439",
            com: IconIntegration
          }
        },
        {
          title: this.$t("Staff Augmentation"),
          content: this.$t("SERVICE[2]"),
          icon: {
            name: "stuff",
            size: "0 0 325 281",
            com: IconStuff
          }
        },
        {
          title: this.$t("And More"),
          content: this.$t("SERVICE[3]"),
          icon: {
            name: "more",
            size: "0 0 345 241",
            com: IconAndMore
          },
          link: this.$t("Contact us to learn more")
        }
      ]
    };
  },
  props: {},
  computed: {
    activeContent() {
      return this.services[this.active];
    },
    size() {
      return {
        xs: { width: "70", height: "70" },
        sm: { width: "82", height: "82" },
        md: { width: "86", height: "86" },
        lg: { width: "115", height: "115" },
        xl: { width: "144", height: "144" }
      }[this.$mq];
    }
  },
  components: {
    IconBase,
    IconArrowright,
    IconIntegration,
    IconPolicies,
    IconStuff,
    IconAndMore
  },
  methods: {}
};
</script>

<style module>
.main {
  background-color: #f8f8f8;
  display: flex;
  margin: 0 0 0 8vw;
  padding: 0 8vw 8vw 0;
  position: relative;
  text-align: left;
  & aside {
    background-color: #000;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -4vw 0 0 -8vw;
    min-width: 40vw;
    padding: 4vw 0 0 8vw;
    & h3 {
      font-size: 2.25rem;
      margin: 0 0 2rem;
    }
    & ul {
      list-style: none;
      margin: 0;
      padding: 2rem 0;
    }
    & li {
      color: rgba(255, 255, 255, 0.35);
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 0.5rem 0;
      &:hover {
        color: #e50112;
      }
      & span {
        padding-right: 4rem;
        white-space: nowrap;
      }
      &.active {
        align-items: center;
        color: #fff;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding-right: 0;
        & span {
          padding-right: 0;
        }
        & hr {
          flex: 1;
          margin: 0 1rem;
          min-width: 1rem;
        }
      }
    }
  }
  & .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: -4vw 0;
    padding: 4vw 0 0 4vw;
    & .icon {
      flex: 1;
    }
    & .contact {
      align-items: center;
      color: #e50012;
      display: flex;
      font-size: 0.8rem;
      font-weight: 600;
      margin: 0;
      text-decoration: none;
      white-space: nowrap;
      & svg {
        margin-left: 1rem;
      }
      &:hover svg {
        animation: 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
        & :global {
          animation-name: shake;
        }
      }
    }
    & h3 {
      font-size: 1.4rem;
      font-weight: 600;
      margin: 0 0 2rem;
    }
    & p {
      font-size: 0.8rem;
      line-height: 1.4;
      margin: 0 0 1rem;
    }
  }

  &.xs,
  &.sm {
    & aside {
      min-width: auto;
      width: 50vw;
      & li {
        & span {
          white-space: normal;
        }
      }
    }
  }
  &.md {
  }
  &.lg {
  }
  &.xl {
  }
}
</style>
