var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{class:( _obj = {}, _obj[_vm.$style.main] = true, _obj[_vm.$style[_vm.$mq]] = true, _obj )},[_c('div',{ref:"swiper-container",class:[_vm.$style.container, 'swiper-container']},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.quotes),function(ref,index){
var content = ref.content;
var avatar = ref.avatar;
var name = ref.name;
var title = ref.title;
return _c('div',{key:index,staticClass:"swiper-slide"},[_c('p',[_vm._v(_vm._s(content))]),_c('div',[_c('img',{attrs:{"src":("" + _vm.baseUrl + avatar),"alt":name}}),_c('span',[_vm._v(_vm._s(name)),_c('br'),_vm._v(_vm._s(title))])])])}),0)]),_c('button',{ref:"btnPrev",class:_vm.$style['btn-prev']}),_c('button',{ref:"btnNext",class:_vm.$style['btn-next']})])}
var staticRenderFns = []

export { render, staticRenderFns }