<template>
  <main
    :class="{
      [$style.main]: true,
      [$style[$mq]]: true,
      [$style.inverse]: true
    }"
  >
    <h3>{{ $t("Our Partners") }}</h3>
    <p>{{ $t('MISC["Our Partners"][0]') }}</p>
    <section :class="$style.wrapper">
      <figure v-for="({ src, alt, href }, index) in images" :key="index">
        <a :href="href" target="_blank" v-if="href">
          <img :src="src" :alt="alt" />
        </a>
        <img :src="src" :alt="alt" v-else />
      </figure>
    </section>
    <p :class="$style.clickaway">{{ $t('MISC["Our Partners"][1]') }}</p>

    <button-base :class="$style.contact" @click="$router.push('/contact')">
      {{ $t("CONTACT US") }}
    </button-base>
  </main>
</template>

<script>
import ButtonBase from "@/components/ButtonBase";

export default {
  name: "KoiPartner",
  data() {
    return {
      images: [
        {
          src: require("../assets/logo-identitymind.png"),
          alt: "IdentityMind"
        },
        {
          src: require("../assets/logo-oceanex.png"),
          alt: "OceanEX"
        },
        {
          src: require("../assets/logo-paretonecapital.png"),
          alt: "ParetoneCapital"
        },
        {
          src: require("../assets/logo-honestcoin.png"),
          alt: "HonestCoin"
        }
      ]
    };
  },
  props: {},
  components: {
    ButtonBase
  },
  methods: {}
};
</script>

<style module>
.main {
  background-color: #f8f8f8;
  padding: 8vw 8vw;
  position: relative;
  & .wrapper {
    border-bottom: 1px solid #979797;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3.2rem;
    padding-bottom: 3.2rem;
    text-align: left;
    & figure {
      background-color: #fff;
      box-sizing: border-box;
      height: 6.125rem;
      margin: 0 0 0.5rem 0;
      padding: 0.563rem;
      position: relative;
      width: 14.875rem;
      & a {
        &:before {
          background-color: rgba(248, 248, 248, 0);
          content: " ";
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          transition: background-color 0.3s ease;
          width: 100%;
        }
        &:hover:before {
          background-color: rgba(248, 248, 248, 1);
        }
      }
      & a img {
        position: relative;
      }
      & img {
        display: block;
        max-width: 100%;
      }
    }
  }
  &.inverse {
    background-color: #fff;
    & .wrapper {
      & figure {
        height: 3.6rem;
        padding: 0.25rem;
        width: 9.0375rem;
      }
    }
  }
  & h3 {
    font-size: 2.25rem;
    margin: -2rem auto 1rem;
    text-align: left;
  }
  & p {
    font-size: 0.9rem;
    line-height: 1.6;
    margin: 0 auto 3.2rem;
    text-align: left;
    &.clickaway {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.2;
      margin-left: 0;
      max-width: 42rem;
    }
  }
  & .contact {
    bottom: 4vw;
    left: 8vw;
    margin: 6rem 0 0 0;
    position: absolute;
  }
  &.xs,
  &.sm {
    & h3 {
      margin: -1rem auto 2rem;
    }
    & .wrapper {
      & figure {
        height: 3.7rem;
        padding: 0.25rem;
        width: 9.3rem;
      }
    }
    & p.clickaway {
      font-size: 1.2rem;
      margin-bottom: 6.4rem;
    }
    & .contact {
      bottom: 8vw;
    }
  }
  &.md {
  }
  &.lg {
    &.inverse {
      & .wrapper figure {
        height: 4.88rem;
        padding: 0.5rem;
        width: 10.1rem;
      }
    }
  }
  &.xl {
    &.inverse {
      & .wrapper figure {
        height: 5rem;
        padding: 0.5rem;
        width: 12.25rem;
      }
    }
  }
}
</style>
