<template>
  <main :class="{ [$style.main]: true, [$style[$mq]]: true }">
    <div :class="[$style.container, 'swiper-container']" ref="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="({ content, avatar, name, title }, index) in quotes"
          :key="index"
        >
          <p>{{ content }}</p>
          <div>
            <img :src="`${baseUrl}${avatar}`" :alt="name" />
            <span>{{ name }}<br />{{ title }}</span>
          </div>
        </div>
      </div>
    </div>
    <button :class="$style['btn-prev']" ref="btnPrev"></button>
    <button :class="$style['btn-next']" ref="btnNext"></button>
  </main>
</template>

<script>
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";

export default {
  name: "KoiCarousel",
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      quotes: [
        {
          content: this.$t("QUOTE[0]"),
          avatar: "img/icons/apple-icon.png",
          name: "Vincent Wang",
          title: this.$t("CEO")
        },
        {
          content: this.$t("QUOTE[1]"),
          avatar: "img/icons/apple-icon.png",
          name: "Shijian Hao",
          title: this.$t("CEO")
        }
      ]
    };
  },
  props: {},
  components: {},
  methods: {},
  mounted() {
    new Swiper(this.$refs["swiper-container"], {
      slidesPerView: "auto",
      spaceBetween: 30,
      autoHeight: true,
      navigation: {
        nextEl: this.$refs.btnNext,
        prevEl: this.$refs.btnPrev
      }
    });
  }
};
</script>

<style module>
.main {
  background-color: #f8f8f8;
  margin: 0 8vw 8vw;
  overflow: hidden;
  padding: 0 4vw;
  position: relative;

  & .container {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    padding: 6vw 0;

    & :global(.swiper-wrapper) {
      align-items: stretch;
    }
    & :global(.swiper-slide) {
      align-items: stretch;
      background: #fff;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.6rem;
      position: relative;
      text-align: left;
      width: 60vw;
      &:after {
        border: 1.2vw solid #fff;
        border-bottom-color: transparent;
        border-right-color: transparent;
        bottom: -2.4vw;
        content: " ";
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
      }
      & p {
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0 1.2rem;
        min-height: 3.2rem;
      }
      & div {
        align-items: center;
        display: flex;
        font-size: 0.9rem;
        line-height: 1.2;
      }
      & img {
        background-color: #f0f0f0;
        border-radius: 50%;
        height: 2.8rem;
        margin-right: 1rem;
        width: 2.8rem;
      }
    }
  }

  & .btn-next,
  & .btn-prev {
    background-color: #000;
    border: 0 none;
    bottom: 0;
    cursor: pointer;
    height: 3vw;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    touch-action: manipulation;
    user-select: none;
    width: 3vw;
    z-index: 1;

    &:before {
      box-sizing: border-box;
      border-style: solid;
      border-color: #fff;
      border-width: 0.5vw 0.5vw 0 0;
      content: " ";
      height: 2vw;
      left: 50%;
      margin: -1vw 0 0 -1vw;
      position: absolute;
      top: 50%;
      transform: matrix(0.25, 0.4, -0.25, 0.4, 0, 0);
      width: 2vw;
    }

    &:hover {
      background-color: #e50112;
    }

    &:global(.swiper-button-disabled) {
      background-color: #ebebeb;
      &:before {
        border-color: #c7c7c7;
      }
    }
  }

  & .btn-prev {
    transform: rotate(0.5turn) translate(100%, 0);
  }

  &.xs,
  &.sm {
    & .container {
      & :global(.swiper-slide) {
        & p {
          min-height: 6.4rem;
        }
      }
    }
  }
  &.md {
  }
  &.lg {
  }
  &.xl {
  }
}
</style>
