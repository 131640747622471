<template>
  <main>
    <Navbar />

    <KoiBanner
      type="home-alt"
      :title="$t('Koi Compliance')"
      @scrollDown-banner="$refs.home.$el.scrollIntoView()"
    />

    <KoiHomeAlt ref="home" />
    <KoiCarousel />
    <KoiAdvantage />
    <KoiService />
    <KoiPartner />

    <Zendesk />

    <KoiFooter />
  </main>
</template>

<script>
import KoiBanner from "@/components/KoiBanner";
import KoiFooter from "@/components/KoiFooter";
import KoiHomeAlt from "@/components/KoiHomeAlt";
import KoiAdvantage from "@/components/KoiAdvantage";
import KoiPartner from "@/components/KoiPartner";
import KoiCarousel from "@/components/KoiCarousel";
import KoiService from "@/components/KoiService";

import Navbar from "@/components/Navbar";
import Zendesk from "@/components/Zendesk";

export default {
  name: "compliance",
  components: {
    KoiBanner,
    KoiFooter,
    KoiHomeAlt,
    KoiAdvantage,
    KoiPartner,
    KoiCarousel,
    KoiService,
    Navbar,
    Zendesk
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<style module></style>
